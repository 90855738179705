import logo from './logo.svg';
import './App.css';

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';


function App() {
  return (
    <div className="App">
      <header className="App-header">
          <div className="child profilePicture">
            <img src={'mark-profesh.jpg'} className="App-logo" alt="Mark's headshot" />
          </div>
          <div className="child details">
            <p>
              Howdy, I'm <span>Mark Glose</span>, and I like to keep things simple.
            </p>
            <p>
              Currently a <span>SSenior Software Developer @ Xevant</span>
            </p>
            <SocialLink url='https://www.linkedin.com/in/mark-glose'><LinkedInIcon style={{ fontSize: 50, marginLeft: -5 }} /></SocialLink>
            <SocialLink url='https://www.instagram.com/phocisticks/'><InstagramIcon style={{ fontSize: 50 }} /></SocialLink>
            <SocialLink url='mailto:mark.glose@gmail.com'><EmailIcon style={{ fontSize: 50}}/></SocialLink>
          </div>
      </header>
    </div>
  );
}

const SocialLink = (props) => (
  <a target="_blank" rel="noopener noreferrer" href={props.url}>
      {props.children}
  </a>
);

export default App;
